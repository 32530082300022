<template>
  <div style="display: flex;flex-direction: column;height: 100%">
    <newHeader url="/Mycenter"></newHeader>

	<!-- <swipper></swipper> -->

	<!-- <div class="content-container"> -->


    <div class="login-container">
      <div style="width: 15%;height: 100%;margin-left: 5%;background-color: #fafafa;float: left;">
        <div style="width: 100%;height: 250px;text-align: center;">
          <el-avatar shape="circle" :size="180" :src="userPhoto"></el-avatar>
          <div title="注册账号" style='font-size: 20px;font-family:"book antiqua";'>{{ userInfo.userAccount }}</div>
          <br>
          <div title="注册手机" style='font-size: 14px;font-family:"book antiqua";'>{{ userInfo.contactPhone }}</div>
        </div>

        <div style="margin-top: 5px;text-align: center;">
          <el-button type="primary" plain @click="$router.push({name: 'myApply'})">我的申请</el-button>
          <el-button type="primary" @click="$router.push({name: 'myPush'})">我的发布</el-button>
        </div>

        <div style="text-align: center;padding-left: 10px;margin: 30px 0;">
          <p v-for="item in menuItems" :class="activeIndex===item.name?'my-item my-item-visited':'my-item'" @click="gotoPage(item.name)" v-text="item.title"></p>
        </div>
      </div>
      <div style="width: 74%;height: 100%;background-color: #fafafa;float: left;margin-left: 5px;">
        <router-view></router-view>
      </div>
    </div>

	<!-- </div> -->
   <Buttom></Buttom>

  </div>
</template>

<script>
import newHeader from "@/components/common/newHeader";
import Buttom from "@/components/common/Buttom";
import UserPhoto from '../../../assets/images/userInfo.jpg';
import Swipper from "@/components/common/index/Swipper";

export default {
  name: "myCenter",
  data: function () {
    return {
      userPhoto: UserPhoto,
      showFlag: 0,
      userInfo: {},
      btnShow: false,
      activeName: '',
      activeIndex: 'userDetail',
      menuItems:[
        {name:'userDetail',title:'个人资料'},
        {name:'templateDown',title:'模板下载'},
        {name:'confirmPay',title:'打款确认'},
        {name:'userPolish',title:'更新资料'},
        {name:'myApplyLog',title:'金融服务'},
        {name:'mySetting',title:'账号设置'}
      ]
    }
  },
  created() {
    let userInfo = sessionStorage.getItem('userinfo');
	if(userInfo==null){
		this.$router.push({name:'Login'});
	}

    let json = JSON.parse(userInfo);
    this.userInfo = json;
    if (!json.unitName || !json.userName) {
      this.btnShow = true;
    } else {
      this.btnShow = false;
    }
    //初始化用户信息
    this.initUserInfo();
    let showFlag = this.$route.params.showFlag;
    if (showFlag) {
      this.showFlag = showFlag;
    }
    let activeName = this.$route.params.activeName;
    if (activeName) {
      this.activeName = activeName;
    }
    this.$router.push({name:'userDetail'});
  },
  components: {
    newHeader,
    Buttom,
    UserPhoto,
	Swipper,
  },
  //路由钩子检查isCorrect状态
  beforeRouteUpdate(to, from, next) {
    console.log(from.path, '=>', to.path,)
    if (to.path !== '/MyCenter/userPolish') {
      this.checkUserInfo();
    }
    next()
  },
  methods: {
    gotoPage(name) {
      this.activeIndex = name;
      if(name === 'confirmPay'){
        this.$router.push({name: name, params: {'key1':'value1'}})
      }else if(name === 'userPolish'){
        this.$router.push({name: name, params : {'userInfo':this.userInfo}})
      }else if(name === 'userPolish'){
        this.$router.push({name: name, params : {'userInfo':this.userInfo}})
      }else{
        this.$router.push({name: name})
      }
    },
    close: function (e) {
      this.showFlag = e.showFlag;
      this.btnShow = e.btnShow;
      sessionStorage.removeItem("userinfo");
      sessionStorage.removeItem("userMsg");
      sessionStorage.removeItem("token");
      this.$store.state.token = '';
      this.$router.push('/login');
    },
    initUserInfo: function () {
      this.HTTP.get('user/detail').then(res => {
        if (res.data.code == 1) {
          this.userInfo = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      }).catch(err => {
        this.$message.error(err);
      })
    },
    checkUserInfo: function () {
      let userInfo = sessionStorage.getItem("userinfo");
      let json = JSON.parse(userInfo);
      if (!json.isCorrect) {
        this.$confirm('您的公司信息尚未完善, 是否立即前往完善资料信息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info'
        }).then(() => {
          this.$router.push({
            name: 'userPolish',
            params: {
              'userInfo': this.userInfo
            }
          })
        }).catch(() => {
        })
        return false;
      } else {
        return true;
      }
    },

  }
}
</script>

<style lang="stylus" scoped>
  .my-item {
    border-bottom 1px solid rgb(241, 241, 241)
    padding 10px 0
    font-size 14px
    color #666
    margin 0
  }

  .my-item-visited {
    color #1a4fcc
    background rgba(31, 89, 233, 0.1)
  }
.login-container
  width 100%
  // height auto;
  box-sizing border-box
  padding 1rem 0
  background #f1f1f1;
  background-size 100% 100%
  flex 1

  .container-box
    background-color white
    margin 0 auto
    width 40rem
    border 1px solid lightgrey
    border-radius 0.5rem
    padding 3rem 5rem

    h1
      color: #2567ab;
      font-size 2.5rem
      text-align center
      margin 2rem 0
      margin-top 0

.content-container
	width 100rem
	height 100rem
	margin 0 auto
	margin-bottom 20px
	box-sizing border-box
	background-color #e2f4ff
	h2
		text-align center
	.search-container
		box-sizing border-box
		background-color lightgrey
		height 5rem
		padding 1rem
		display flex
		justify-content space-around
</style>
